<template>
  <div class="bottom-nav-box FONT_SHSR">
    <div class="record-detail">
      <!-- 蜀ICP备xxxxxxxx号-1      ICP证:川B2 -2022445      教APP4400444号       粤公网安备xxxxxxxxxxxxxx号 -->
      粤ICP备2022052684号{{ showFilingNumber }}
    </div>

    <div class="tab">
      <p @click="navPage('/')">首页</p>
      <div class="bar"></div>
      <p @click="navPage('/introduction')">产品介绍</p>
      <div class="bar"></div>
      <p @click="navPage('/about')">关于我们</p>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {},
  setup(props) {
    const router = useRouter();

    const navPage = (url) => {
      router.push(url);
    };

    // 备案号
    const showFilingNumber = computed(() => {
      // 获取 location
      const location = window.location;
      let { host } = location;
      const hosts = host.split(".");
      const domainName = hosts[hosts.length - 1];
      if (domainName == "com") {
        return "-4";
      }
      if (domainName == "cn") {
        return "-1";
      }
    });

    return {
      navPage,
      showFilingNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav-box {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10%;
  border-top: 0.5px solid #e6ebf3;

  .record-detail {
    font-size: 12px;
    color: #c4c4c4;
  }

  .bar {
    width: 1px;
    height: 15px;
    background-color: black;
    margin: 0 10px;
  }

  .tab {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    p {
      cursor: pointer;
    }
  }
}
</style>
