<template>
  <div
    class="top-nav-box"
    :style="{ color: color, backgroundImage: `url(${image})`, height }"
  >
    <!-- 顶部选项卡 -->
    <div class="top-box" :style="topNavStyle">
      <div class="logo">
        <img src="../assets/images/logo.png" alt="" />
      </div>

      <div class="tab-box">
        <div
          class="tab-item"
          :class="{ FONT_SHSB: tabValue == index }"
          v-for="(item, index) in topTab"
          :key="'tab' + index"
          @click="tabValue = index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    currTab: {
      type: [String, Number],
      default: "0",
    },
    color: {
      type: String,
      default: "black",
    },
    // banner
    banner: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "800px",
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const router = useRouter();

    const image = ref(props.banner);

    // 顶部导航
    const topTab = [
      {
        name: "首页",
        path: "/",
        value: 0,
      },
      {
        name: "产品介绍",
        path: "/introduction",
        value: 1,
      },
      {
        name: "关于我们",
        path: "/about",
        value: 2,
      },
    ];
    // 选项值
    const tabValue = ref(props.currTab);
    // 监听跳转
    watch(
      () => tabValue.value,
      (nValue) => {
        let item = topTab[nValue];
        router.push(item.path);
      }
    );

    // 初始
    const scrollVal = ref(0);
    const topNavStyle = computed(() => {
      let val = scrollVal.value;
      let style = "";

      if (val >= 50) {
        style = `background-color:white;color:white;`;
      } else {
        style = `background-color:tranparent;color:white;`;
      }

      return style;
    });

    const init = () => {
      window.onscroll = function () {
        //为了保证兼容性，这里取两个值，哪个有值取哪一个
        //scrollTop就是触发滚轮事件时滚轮的高度
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;

        scrollVal.value = scrollTop;
      };

      // 检索 选项卡索引
      const routePAthIndex = topTab.findIndex(
        (d) => d.path === proxy.$route.path
      );
      tabValue.value = routePAthIndex;
    };

    onMounted(() => {
      init();
    });

    return {
      topTab,
      tabValue,
      topNavStyle,
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-nav-box {
  background-image: url(../assets/images/banner.png);
  width: 100%;
  // height: 800px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 1920px 800px;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;

  // 顶部选项卡
  .top-box {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    transition: 300ms;
    background: linear-gradient(135deg, #2785ff 0%, #2567ff 100%);
    z-index: 9;

    .logo {
      width: 50%;

      img {
        width: 40px;
      }
    }

    .tab-box {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 20px;
      justify-content: space-between;
      // width: 260px;

      .tab-item {
        padding: 0 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
